import autocomplete from 'autocompleter';
import htmx from 'htmx.org';

interface AnyAutoComplete {
  label: string;
  value: string;
}

(() => {
  htmx.defineExtension('any-auto-complete', {
    onEvent: (name, event) => {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLInputElement;
      const { hidden: hiddenId, url } = el.dataset;
      const hidden = hiddenId ? (document.getElementById(hiddenId) as HTMLInputElement) : null;

      if (url) {
        autocomplete<AnyAutoComplete>({
          input: el,
          minLength: 3,
          debounceWaitMs: 300,
          preventSubmit: 1,
          fetch: async (text, update) => {
            text = text.toLowerCase();
            const response = await fetch(`${url}?q=${text}`, {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            });
            if (response.ok) {
              const results = (await response.json()) as AnyAutoComplete[];
              const suggestions = results.filter((result) => result.label.toLowerCase().includes(text));
              update(suggestions);
            }
          },
          onSelect: (item) => {
            if (hidden) {
              hidden.value = item.value;
            }
            el.value = item.label;
          }
        });
      }

      el.addEventListener('input', () => {
        if (!el.value && hidden) {
          hidden.value = '';
        }
      });
    }
  });
})();
