import htmx from 'htmx.org';

const ext = 'treatment-selector';

(() => {
  htmx.defineExtension(ext, {
    onEvent: function (name, event) {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLElement;
      const selectedClass = 'sl-menu-item-selected';

      el.addEventListener('sl-select', (e) => {
        const item = (<CustomEvent>e).detail.item;

        el.querySelectorAll('.sl-select').forEach((item) => item.classList.remove(selectedClass));
        item.classList.add(selectedClass);
        const treatment = item.getAttribute('value');
        const gallery = document.querySelector('.magic-gallery');
        if (gallery) {
          gallery.classList.forEach((className) => {
            if (className.startsWith('filter-')) {
              gallery.classList.remove(className);
            }
          });
          gallery.classList.add(`filter-${treatment}`);
        }

        htmx.ajax('POST', '/set-treatment', {
          swap: 'none',
          values: { selections: { treatment: treatment } }
        });
      });
    }
  });
})();
