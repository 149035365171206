import autocomplete, { AutocompleteItem } from 'autocompleter';
import htmx from 'htmx.org';

interface SearchAutoCompleteItem extends AutocompleteItem {
  id: string;
  value: string;
  model: string;
  href: string;
}
interface SearchAutoComplete {
  data: SearchAutoCompleteItem[];
}

(() => {
  htmx.defineExtension('search-auto-complete', {
    onEvent: (name, event) => {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLInputElement;
      const { url } = el.dataset;

      if (url) {
        autocomplete<SearchAutoCompleteItem>({
          input: el,
          minLength: 3,
          debounceWaitMs: 300,
          preventSubmit: 1,
          fetch: async (text, update) => {
            text = text.toLowerCase();
            const response = await fetch(`${url}/${text}.json`, {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            });
            if (response.ok) {
              const results = (await response.json()) as SearchAutoComplete;
              const suggestions = results.data.filter((result) => result.value.toLowerCase().includes(text));
              update(suggestions);
            }
          },
          render: (item) => {
            const itemEl = document.createElement('div');
            itemEl.textContent = item.value;
            return itemEl;
          },
          onSelect: (item) => {
            return (window.location.href = item.href);
          }
        });
      }
    }
  });
})();
