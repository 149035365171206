import htmx from 'htmx.org';

(() => {
  htmx.defineExtension('role-copy', {
    onEvent: (name, event) => {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLLinkElement;
      const { url, role: destRole } = el.dataset;

      el.addEventListener('click', (event) => {
        event.preventDefault();

        const srcRole = 'bride';
        const srcFields = Array.from(document.querySelectorAll(`[id^="${srcRole}"]`));
        const destFields = Array.from(document.querySelectorAll(`[id^="${destRole}"]`));
        const blacklist = [`${srcRole}_first_name`, `${srcRole}_last_name`];

        document.querySelectorAll('.error-message').forEach((error) => {
          error.textContent = '';
        });

        const data = {} as { [key: string]: string };
        destFields.forEach((field, index) => {
          const srcField = srcFields[index] as HTMLInputElement;
          if (
            srcField &&
            !blacklist.includes(srcField.id) &&
            (field instanceof HTMLInputElement || field instanceof HTMLSelectElement)
          ) {
            field.value = srcField.value;
            data[field.name] = field.value;
          }
        });

        if (url) {
          htmx.ajax('POST', url, {
            values: data,
            target: undefined,
            swap: 'none'
          });
        }
      });
    }
  });
})();
