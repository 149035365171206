interface CollectJSReponse {
  token: string;
}

interface CollectJSFields {
  [index: string]: boolean;
}

document.addEventListener('DOMContentLoaded', function () {
  // @ts-expect-error CollectJS is a global variable
  if (typeof window.CollectJS === 'undefined') {
    return;
  }

  // @ts-expect-error CollectJS is a global variable
  const CollectJS = window.CollectJS;

  const paymentBtn = document.getElementById('paymentBtn') as HTMLInputElement;
  const paymentToken = document.getElementById('payment_token') as HTMLInputElement;
  const collectLoader = document.getElementById('collectLoader') as HTMLDivElement;
  const fields: CollectJSFields = {
    ccnumber: false,
    ccexp: false,
    cvv: false
  };

  CollectJS.configure({
    paymentSelector: '#paymentBtn',
    variant: 'inline',
    styleSniffer: 'true',
    invalidCss: {
      color: '#991b1b',
      'background-color': '#fef2f2'
    },
    validCss: {
      color: '#15803d',
      'background-color': '#f0fdf4'
    },
    placeholderCss: {
      color: '#d1d5db'
    },
    fields: {
      ccnumber: {
        selector: '#collectCardNum',
        title: 'Card Number',
        placeholder: '0000 0000 0000 0000'
      },
      ccexp: {
        selector: '#collectExpDate',
        title: 'Card Expiration',
        placeholder: '00 / 00'
      },
      cvv: {
        display: 'required',
        selector: '#collectSecurityCode',
        title: 'Security Code',
        placeholder: '***'
      }
    },
    timeoutDuration: 20000,
    validationCallback: function (field: string, status: boolean) {
      fields[field] = status;
      if (Object.values(fields).every((field) => field === true)) {
        if (!paymentToken.value.length) {
          CollectJS.startPaymentRequest();
          collectLoader.classList.remove('hidden');
        }
      } else {
        paymentBtn.disabled = true;
        paymentBtn.classList.add('disabled');
      }
    },
    callback: function (response: CollectJSReponse) {
      paymentToken.value = response.token;
      const error = paymentToken.parentElement?.nextElementSibling as HTMLElement;
      if (error) {
        error.textContent = '';
      }
      paymentBtn.disabled = false;
      paymentBtn.classList.remove('disabled');
      collectLoader.classList.add('hidden');
    }
  });
});
