import htmx from 'htmx.org';

const ext = 'style-selector';

interface SlDropdown extends HTMLElement {
  hide: () => void;
}

(() => {
  htmx.defineExtension(ext, {
    onEvent: function (name, event) {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLElement;
      const btn = el.querySelector('button') as HTMLButtonElement;
      const selectedClass = 'sl-menu-item-selected';
      const checkedClass = 'sl-menu-item-checked';
      let reset = false;

      el.addEventListener('sl-select', (e) => {
        const item = (<CustomEvent>e).detail.item;

        if (item.classList.contains('sl-select')) {
          el.querySelectorAll('.sl-select').forEach((item) => item.classList.remove(selectedClass));
          item.classList.add(selectedClass);
        } else if (item.classList.contains('sl-checkbox')) {
          if (!reset) {
            el.querySelectorAll('.sl-checkbox').forEach((item) => item.classList.remove(checkedClass));
            reset = true;
          }
          item.classList.toggle(checkedClass);
        }
      });

      btn.addEventListener('click', (e) => {
        e.preventDefault();
        const allCheckedStyles = el.querySelectorAll('.sl-menu-item-checked') as NodeListOf<HTMLInputElement>;
        const styles = Array.from(allCheckedStyles).map((item) => item.value);
        const treatment = el.querySelector('.sl-menu-item-selected')?.getAttribute('value');

        (el.closest('sl-dropdown') as SlDropdown).hide();

        htmx
          .ajax('POST', '/portfolios', {
            swap: 'none',
            values: { selections: { treatment: treatment, styles: styles } }
          })
          .then(() => {
            window.location.href = '/portfolios';
          });
      });
    }
  });
})();
