import htmx from 'htmx.org';

(() => {
  htmx.defineExtension('hour-slider', {
    onEvent: (name, event) => {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLInputElement;
      const { limiter, controls, minValue, minValueMsg, rate, url } = el.dataset;
      const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

      const getBasePackage = () => {
        const basePackageVal = (document.getElementById('base_package') as HTMLInputElement).value;
        return basePackageVal ? JSON.parse(basePackageVal) : {};
      };

      const getDisplayEl = (el: HTMLElement, name: string) => htmx.find(el.nextElementSibling as HTMLElement, name);
      const displayHours = (el: HTMLInputElement) => `${el.value} hour${el.value === '1' ? '' : 's'}`;

      const displayResults = (el: HTMLInputElement, min: number) => {
        const hoursEl = getDisplayEl(el, '.hours');
        if (hoursEl) {
          hoursEl.textContent = displayHours(el);
        }

        const priceEl = getDisplayEl(el, '.price');
        const value = min > 0 ? min : parseFloat(el.value);
        if (priceEl && rate) {
          const total = parseFloat(rate) * value;
          priceEl.textContent = total > 0 ? formatter.format(total) : '';
        }
      };

      const toggleStatusMsg = (el: HTMLElement, msg: string, status: boolean) => {
        const msgEl = htmx.find(htmx.closest(el, 'div') as HTMLElement, '.msg');
        if (msgEl) {
          msgEl.textContent = msg;
          msgEl.classList.toggle('hidden', !status);
        }
      };

      const updateSidebar = () => {
        if (url) {
          htmx.ajax('POST', url, {
            source: htmx.find('form'),
            target: htmx.find('#sidebar'),
            swap: 'outerHTML swap:.2s'
          });
        }
      };

      el.addEventListener('input', () => {
        const selectedPackage = getBasePackage();

        if (limiter) {
          const limiterEl = htmx.find(`#${limiter}`) as HTMLInputElement;
          const limiterCurrentValue = limiterEl ? parseFloat(limiterEl.value) : 0;
          const limiterPackageValue = parseFloat(selectedPackage[limiter]);
          const thisPackageValue = parseFloat(selectedPackage[el.id]);
          const limiterTotal = limiterCurrentValue + limiterPackageValue - thisPackageValue;
          if (limiterTotal > 0 && parseFloat(el.value) > limiterTotal) {
            el.value = limiterTotal.toString();
          }
        }

        if (controls) {
          const controlsEl = htmx.find(`#${controls}`) as HTMLInputElement;
          const controlsCurrentValue = controlsEl ? parseFloat(controlsEl.value) : 0;
          const controlsPackageValue = parseFloat(selectedPackage[controls]);
          const controlsTotal = controlsCurrentValue + controlsPackageValue;
          const thisTotal = parseFloat(el.value) + parseFloat(selectedPackage[el.id]);
          if (controlsTotal > thisTotal) {
            const newControlsTotal = thisTotal - controlsPackageValue;
            controlsEl.value = newControlsTotal.toString();
            const controlsRate = controlsEl.dataset['rate'];
            const controlsHoursEl = getDisplayEl(controlsEl, '.hours');
            const controlsPriceEl = getDisplayEl(controlsEl, '.price');
            if (controlsHoursEl && controlsPriceEl && controlsRate) {
              controlsHoursEl.textContent = displayHours(controlsEl);
              const controlsDisplayTotal = parseFloat(controlsRate) * newControlsTotal;
              controlsPriceEl.textContent = controlsDisplayTotal > 0 ? formatter.format(controlsDisplayTotal) : '';
            }
          }
        }

        displayResults(el, 0);
      });

      el.addEventListener('pointerup', () => {
        if (minValue && parseFloat(el.value) !== 0 && parseFloat(el.value) < parseFloat(minValue)) {
          displayResults(el, parseFloat(minValue));
          toggleStatusMsg(el, minValueMsg as string, true);
        } else {
          toggleStatusMsg(el, '', false);
        }
        updateSidebar();
      });
    }
  });
})();
