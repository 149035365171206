import htmx from 'htmx.org';

(() => {
  htmx.defineExtension('payment-plans', {
    onEvent: (name, event) => {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLInputElement;
      const { url, min, max } = el.dataset;
      const today = new Date().toISOString().slice(0, 10);

      el.addEventListener('change', () => {
        let eventDate = '';
        let deferredDate = today;

        switch (el.id) {
          case 'wedding_date':
            eventDate = el.value;
            (document.getElementById('payment_deferred_start') as HTMLInputElement).value = deferredDate;
            break;
          case 'payment_deferred_start':
            eventDate = (document.getElementById('wedding_date') as HTMLInputElement).value;
            deferredDate = el.value;
            break;
        }

        if (min && max && (deferredDate < min || deferredDate > max)) {
          deferredDate = today;
        }

        if (url) {
          htmx.ajax('GET', `${url}?date=${eventDate}&start=${deferredDate}`, {
            target: '#payment-plans',
            swap: 'innerHTML swap:.2s'
          });
        }
      });
    }
  });
})();
