import autocomplete from 'autocompleter';
import htmx from 'htmx.org';

interface VendorAutoComplete {
  label: string;
  value: string;
}

interface HTMXRequest {
  xhr: XMLHttpRequest;
  target: HTMLElement;
}

(() => {
  htmx.defineExtension('vendor-auto-complete', {
    onEvent: (name, event) => {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLInputElement;
      const { hidden: hiddenId, url, redirectMsg } = el.dataset;
      const hidden = hiddenId ? (document.getElementById(hiddenId) as HTMLInputElement) : null;

      if (url) {
        autocomplete<VendorAutoComplete>({
          input: el,
          minLength: 3,
          debounceWaitMs: 300,
          preventSubmit: 1,
          fetch: async (text, update) => {
            text = text.toLowerCase();
            const response = await fetch(`${url}?q=${text}`, {
              headers: {
                'X-Requested-With': 'XMLHttpRequest'
              }
            });
            if (response.ok) {
              const results = (await response.json()) as VendorAutoComplete[];
              const suggestions = results.filter((result) => result.label.toLowerCase().includes(text));
              update(suggestions);
            }
          },
          onSelect: (item) => {
            if (hidden) {
              hidden.value = item.value;
              htmx.ajax('POST', url, {
                source: hidden,
                target: undefined,
                swap: 'none',
                handler: handler
              });
            }
            el.value = item.label;
          }
        });

        const handler = (_input: HTMLInputElement, request: HTMXRequest) => {
          if (request.xhr.status === 302 && redirectMsg) {
            alert(redirectMsg);
            return (window.location.href = request.xhr.responseText);
          }
          return;
        };
      }

      el.addEventListener('input', () => {
        if (!el.value && hidden) {
          hidden.value = '';
        }
      });
    }
  });
})();
