import htmx from 'htmx.org';

(() => {
  htmx.defineExtension('validate-form', {
    onEvent: (name, event) => {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLLinkElement;
      const form = el.closest('form') as HTMLFormElement;

      el.addEventListener('click', (event) => {
        event.preventDefault();

        const { url } = el.dataset;
        if (url) {
          htmx
            .ajax('POST', url, {
              values: htmx.values(form),
              target: '#errors',
              swap: 'innerHTML',
              headers: {
                'X-Validate-All': 'true'
              }
            })
            .then(() => {
              const errors = document.getElementById('errors');
              if (errors && errors.textContent !== '') {
                const errorData = JSON.parse(errors.textContent as string);
                Object.keys(errorData).forEach((key) => {
                  const parent = htmx.closest(htmx.find(`#${key}`) as HTMLElement, 'div') as HTMLElement;
                  const errorEl = parent.nextElementSibling;
                  if (errorEl && errorEl.classList.contains('error-message')) {
                    errorEl.textContent = errorData[key];
                    errorEl.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                  }
                });
              } else {
                el.disabled = true;
                el.classList.add('disabled');
                form.submit();
              }
            });
        }
      });
    }
  });
})();
